/**
 * 时间戳
 * @param {*} timestamp  时间戳
 */
const timestampToTime = (timestamp) => {
  const date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + '-'
  const M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
  const D =
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m =
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':'
  const s =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
}
/**
 * 存储localStorage
 */
const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}

/**
 * 获取localStorage
 */
const getStore = name => {
  if (!name) return
  return window.localStorage.getItem(name)
}

/**
 * 删除localStorage
 */
const removeStore = name => {
  if (!name) return
  window.localStorage.removeItem(name)
}

/**
 * 设置cookie
 **/
function setCookie(name, value, day) {
  const date = new Date()
  date.setDate(date.getDate() + day)
  document.cookie = name + '=' + value + ';expires=' + date
}

/**
 * 获取cookie
 **/
function getCookie(name) {
  const reg = RegExp(name + '=([^;]+)')
  const arr = document.cookie.match(reg)
  if (arr) {
    return arr[1]
  } else {
    return ''
  }
}

/**
 * 删除cookie
 **/
function delCookie(name) {
  setCookie(name, null, -1)
}

const validFormAndInvoke = (formEl, success, message = '信息有误', fail = function() {
}) => {
  if (!formEl) {
    return
  }
  formEl.validate(valid => {
    if (valid) { // form valid succeed
      // do success function
      success()
      // reset fields
      formEl.resetFields()
    } else { // form valid fail
      Vue.prototype.$notify({
        title: 'Tips',
        message: message,
        type: 'error',
        duration: 2000
      })
      // do something when fail
      fail()
      return false
    }
  })
}

/**
 * 导出
 **/
export {
  timestampToTime,
  setStore,
  getStore,
  removeStore,
  setCookie,
  getCookie,
  delCookie,
  validFormAndInvoke
}
